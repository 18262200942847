import { Checkbox, Pagination, Table } from "antd";
import { CSVLink } from "react-csv";
import { IoMdRefresh } from "react-icons/io";
import { useLocation } from "react-router";
import styled from "styled-components";
import tw from "twin.macro";
import { convertToCSV } from "../../utils";
import { PageTitle } from "../../utils/data";
import Filter from "./Filter";
import PaginationBars from "./Paginationbars";
import SearchBar from "./SearchBar";
import StyledButton from "./btn";
// import Pagination from '../../views/Pagination/Pagination';
import { IoChevronDownSharp } from "react-icons/io5";
import WasteFilter from "./WasteFilter";
import { format, parseISO, isSameDay } from 'date-fns';
import { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import InvoiceStatusFilter from "./InvoiceStatusFilter";


const topOptions = [
  { label: "topLeft", value: "topLeft" },
  { label: "topCenter", value: "topCenter" },
  { label: "topRight", value: "topRight" },
  { label: "none", value: "none" },
];

const bottomOptions = [
  { label: "bottomLeft", value: "bottomLeft" },
  { label: "bottomCenter", value: "bottomCenter" },
  { label: "bottomRight", value: "bottomRight" },
  { label: "none", value: "none" },
];

const TableContainer = styled.div`
  ${tw`bg-white h-full`}
  overflow-y: scroll;
`;
const FilterFix = styled.div`
  // margin-left: 36rem;
  display: ${({ raffle }) => (raffle ? "none" : "")};
  // margin-top: 20px;
`;
const RefeshContainer = styled.button`
  ${tw`flex items-center gap-2  text-base`}
  cursor: pointer;
  // position: absolute;
  // top: 10px;
  // margin-bottom: -40px;
`;
const PaginationContainer = styled.div`
  ${tw`flex items-center justify-between w-full  overflow-x-hidden`}
  padding: .5rem 1.5rem;
`;
const FilterCSVHandler = styled.div`
  ${tw`flex items-center md:flex-row flex-col gap-4`}
`;

const DataTable = ({
  data,
  columns,
  loading,
  refreshUrl,
  header,
  onRow,
  raffle,
  onSearch,
  onFilter,
  onActivityFilter,
  onFetch,
  onRefresh,
  setCurrentPage,
  totalPages,
  paginationData,
  noFilter,
  nopagination,
  hideFilter,
  showSearchBar,
  showCSV,
  hideTable,
  node,
  isPaginate,
  ratedUsers,
  setRatedUsers,
  tname,
  dateRange
}) => {
  const location = useLocation();
  const getTitle = location.pathname.split("/");
  let getTitleEnum = getTitle[getTitle.length - 1];
  const pageSize = 20;

  const [csvData, setCsvData] = useState([])

  const handleRefresh = () => {
    onRefresh();
  };


  const getExportField =()=>{
    const colKeys = columns?.map((i)=> i.key)
    console.log(colKeys)
    const result = data?.map(item => 
      colKeys.reduce((acc, key) => {
        acc[key] = item[key];
        return acc;
      }, {})
    );

    console.log(result)

    setCsvData(result)
  }

  useEffect(()=>{
    getExportField()
  }, [data])

  const onChange = (page) => {
    //gitlab.com/pakam-project/pakamwebrevamp.git
    https: setCurrentPage(page);
  };

  const pullData = (page) => {
    // console.log(paginationData, "paginationData");
    if (
      !paginationData.key &&
      !(paginationData.date?.start || paginationData.date?.end)
    ) {
      onFetch(page);
      return;
    }
    paginationData.key
      ? onSearch(paginationData.key, page)
      : onFilter(paginationData.date, page);
  };

  const handleCheck = (e) => {
    setRatedUsers(e.target.checked);
  };


  const [isDateRange, setIsDateRange] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState('');

  useEffect(()=>{
    if (dateRange && dateRange.start){
      setIsDateRange(true)
    }
  }, [dateRange])

  const handleRangeValue = (value)=>{
    console.log(value)
    setDateRangeValue(value)
  }

  return (
    <TableContainer>
      <div>
        {header ? (
          <>
            <div className="flex justify-between items-center px-5 py-2">
              <div className="flex items-center gap-2">
                {!showSearchBar && <SearchBar onSearch={onSearch} />}

                {!showCSV && (
                  <>
                    {data && tname === "Mobile Users" &&  (
                      <>
                        <StyledButton
                          buttonStyle="btn--gray--outline"
                          buttonSize="btn--small"
                          className="flex items-center gap-6 text-base"
                        >
                          <img
                            src="/assets/images/sliders.svg"
                            alt="filter-icon"
                            className="iconStyle1"
                          />
                        </StyledButton>
                        <FilterFix raffle={raffle} show={noFilter}>
                          {!hideFilter && <Filter icon={<IoChevronDownSharp/>} compTitle={'Custom'} title={'Date'} onFilter={onFilter} rangeValue={handleRangeValue} />}
                        </FilterFix>
                        <FilterFix raffle={raffle} show={noFilter}>
                          {!hideFilter && <Filter comp={<WasteFilter onFilter={onActivityFilter} />} icon={<IoChevronDownSharp/>} compTitle={"User's Activity"} title={'User Activity'} />}
                        </FilterFix>

                        {
                          isDateRange && dateRangeValue !== 'all' && (
                              dateRange && dateRange.start && 
                                <div className="border rounded-xl bg-[#F5F6F7] border-[#D0D5DD] py-1 px-3 flex justify-center items-center">
                                  <span className="text-[#667185] font-[500] font-Int text-[14px]"><DateRangeFormatter dateRange={`${dateRange?.start} - ${dateRange.end}`} /></span>
                                   {/* <button onClick={()=>setIsDateRange(false)} className=" ml-2  rounded-full inline-flex"><MdCancel/></button> */}
                                </div>                              
                          )
                        }


                      </>

                    )}
                  </>
                )}

                {
                    data && tname === "Invoice" &&  (
                      <>
                        <FilterFix raffle={raffle} show={noFilter}>
                          {!hideFilter && <Filter comp={<InvoiceStatusFilter onFilter={onActivityFilter} />} icon={<IoChevronDownSharp/>} compTitle={"Filter with Status"} title={'Filter with Status'} />}
                        </FilterFix>

                      </>

                    )
                }

              </div>
              

              <FilterCSVHandler>
                {location.pathname === "/admin/total_users" && tname !== "Mobile Users" && (
                  <div className="flex gap-2 items-center border-[1px] rounded-lg border-primary px-3 py-1 hover:text-white hover:bg-secondary transition-all ease-in-out duration-500 cursor-pointer">
                    <Checkbox onChange={handleCheck} checked={ratedUsers} />
                    <p className="text-xs font-semibold">Top Rated</p>
                  </div>
                )}

                <FilterFix raffle={raffle} show={noFilter}>
                  {!hideFilter && tname !== "Mobile Users" && <Filter onFilter={onFilter} />}
                </FilterFix>

                {!showCSV && (
                  <>
                    {data &&  (
                      <StyledButton
                        raffle
                        buttonStyle="btn--primary--outline"
                        buttonSize="btn--small"
                        // className="hidden md:block"
                      >
                        <CSVLink
                          data={data && convertToCSV(csvData)}
                          filename={PageTitle[getTitleEnum]}
                          className="text-secondary hover:text-white font-semibold"
                        >
                          Export as CSV
                        </CSVLink>
                      </StyledButton>
                    )}
                  </>
                )}
              </FilterCSVHandler>
            </div>
            <hr />
          </>
        ) : null}
      </div>
      <>
        <PaginationContainer>
          <RefeshContainer onClick={() => handleRefresh()}>
            <IoMdRefresh style={{ fontSize: "1.3rem" }} /> Refresh
          </RefeshContainer>

          {!isPaginate ? (
            <>
              {!paginationData ? (
                !nopagination ? (
                  <Pagination
                    simple
                    defaultCurrent={1}
                    pageSize={pageSize}
                    total={totalPages}
                    onChange={onChange}
                  />
                ) : null
              ) : (
                <PaginationBars {...paginationData} pullData={pullData} />
              )}
            </>
          ) : null}
        </PaginationContainer>
        {!hideTable ? (
          <Table
            loading={loading}
            columns={columns}
            onRow={onRow}
            // pagination={{
            //   position: ["topRight"],
            //   pageSize: pageSize,
            //   total: totalPages,
            //   onChange: onChange,
            // }}
            pagination={false}
            dataSource={data}
            responsive={true}
            scroll={{ x: 800 }}
            sticky={true}
          ></Table>
        ) : (
          node
        )}
      </>
    </TableContainer>
  );
};
export default DataTable;




const DateRangeFormatter = ({ dateRange }) => {
  // Split the input string into start and end dates
  const [startDateStr, endDateStr] = dateRange.split(' - ');

  // Parse the dates
  const startDate = parseISO(startDateStr);
  const endDate = parseISO(endDateStr);

  // Check if the start and end dates are the same
  const sameDay = isSameDay(startDate, endDate);

  // Format the dates based on the condition
  const formattedDate = sameDay
    ? format(startDate, 'MMM dd, yyyy')
    : startDate.getFullYear() === endDate.getFullYear()
    ? `${format(startDate, 'MMM dd')} - ${format(endDate, 'MMM dd, yyyy')}`
    : `${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`;

  return <div>{formattedDate}</div>;
};


