import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ContentCard from "../../components/UI/ContentCard";
import InfoModal from "../../components/UI/InfoModal";
import Tabcontent from "../../components/UI/TabContent";
import StyledButton from "../../components/UI/btn";
import {
  currentMonthUser,
  filterPointUssdUser,
  filterUser,
  filterUssdUser,
  mobileFilterUser,
  searchUser,
  searchUssdUser,
  totalUser,
  totalUssdUsers,
} from "../../store/actions";
import { TotalCardUser, colors } from "../../utils/data";
import { capitalize } from "../../utils";
import { filterUserActivity } from "../../store/actions/userAction";

export const LinkText = ({title})=>{
  const titleText = capitalize(title)
  return(
    <p className="text-[#008300] font-Rale font-[400] text-[14px] underline">{titleText}</p>
  )
}

const TotalUser = () => {

  const viewtype = 'transaction'
  /****************************
   *
   * states and hooks
   *
   ****************************/
  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [bodyData, setBodyData] = useState();
  const [paginationData, setPaginationData] = useState();
  const dispatch = useDispatch();
  const [ussdPaginationData, setUssdPaginationData] = useState([]);
  const [ussdBodyData, setUssdBodyData] = useState([]);
  const [selectedKey, setSelectedKey] = useState("0");
  const [checkedRatedUsers, setCheckedRatedUsers] = useState(false);
  const [filterDate, setFilterDate] = useState({});

  const d = new Date();

  d.setDate(d.getDate());
  const payload = {
    start: "2020-01-01",
    end: d,
  };


  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      searchUser({
        key,
        page,
      })
    );
    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setBodyData(users);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const onFilter = async (date, page = 1) => {

    setFilterDate(date)

    const res = await dispatch(
      filterUser({
        page,
        currentMonth: date,
      })
    );

    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setBodyData(users);
      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const activityFilter = async (status, type, page = 1 ) => {
    console.log(status)
    console.log(type)
    const res = await dispatch(
      filterUserActivity({
        page,
        type,
        currentMonth: filterDate?.start ? filterDate : payload,
        status
      })
    );

    if (!res.error) {
      console.log(res.payload.data)
      const { data, ...paginationData } = res.payload.data;
      setBodyData(data);
      setPaginationData({ ...paginationData, filterDate });
      setTotalPages(paginationData.totalPages);
    }
  };

  const mobileFilter = async (date, page = 1) => {
    const res = await dispatch(
      mobileFilterUser({
        page,
        currentMonth: date,
      })
    );

    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setBodyData(users);
      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const thisMonth = useSelector((state) => state?.user);
  const { currentMonthClient, allUser } = thisMonth;

  useEffect(() => {
    if (!allUser) dispatch(totalUser());
  }, []);

  const totalUserCopy = TotalCardUser.map((el) => {
    return { ...el };
  });

  totalUserCopy[0].user = allUser?.male;
  totalUserCopy[1].user = allUser?.female;
  totalUserCopy[2].user = allUser && allUser?.female + allUser?.male;

  const fetchAll = async (page = 1) => {
    const res = await dispatch(
      currentMonthUser({
        page,
        currentMonth: payload,
      })
    );
    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setBodyData(users);
      setPaginationData({ ...paginationData, date: payload });
    }
  };

  const fetchAllUssdUsers = async (page = 1) => {
    const res = await dispatch(totalUssdUsers({ currentMonth: payload, page }));
    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setUssdBodyData(users);
      setUssdPaginationData({ ...paginationData, date: payload });
    }
  };

  const searchUssdUsers = async (key, page = 1) => {
    const res = await dispatch(
      searchUssdUser({
        key,
        page,
      })
    );

    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setUssdBodyData(users);
      setUssdPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const filterUssdUsers = async (date, page = 1) => {
    const res = await dispatch(
      filterUssdUser({
        page,
        currentMonth: date,
      })
    );

    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setUssdBodyData(users);
      setUssdPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const pointFilterUssdUsers = async (date, page = 1) => {
    const res = await dispatch(
      filterPointUssdUser({
        page,
        currentMonth: date,
      })
    );

    if (!res.error) {
      const { users, ...paginationData } = res.payload.data;
      setUssdBodyData(users);
      setUssdPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  useEffect(() => {
    fetchAllUssdUsers();
  }, []);

  const onRefresh = () => {
    fetchAll();
    fetchAllUssdUsers();
  };

  const onSwitch = (key) => {
    setSelectedKey(key);
  };

  useEffect(() => {
    onRefresh();
  }, []);


  console.log(paginationData)

  const data = [
    {
      title: "USSD Users",
      data: ussdBodyData,
      totalPages: ussdPaginationData?.totalPages,
      paginationData: ussdPaginationData,
      filterHandler: checkedRatedUsers ? pointFilterUssdUsers : filterUssdUsers,
      searchHandler: searchUssdUsers,
      fetch: fetchAllUssdUsers,

      columns: [
        {
          title: "Full Name",
          dataIndex: "fullname",
          key: "fullname",
          // render: (text) => <a>{text}</a>,
        },
        {
          title: "User Category",
          dataIndex: "accountType",
          key: "accountType",
          render: (_, record) => {
            return <Space size="middle">{record?.accountType?.title}</Space>;
          },
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Completed Schedules",
          dataIndex: "schedulePoints",
          key: "schedulePoints",
        },

        {
          title: "Customer Phone",
          dataIndex: "phone",
          key: "phone",
        },

        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Link
                  to={{
                    pathname: `/admin/user_details/${record._id}`,
                    state: { renewal: false },
                    // id: record.key
                  }}
                >
                  <StyledButton
                    type=""
                    buttonStyle="btn--primary--outline"
                    buttonSize="btn--small"
                    onClick={() => {
                      setRowInfo(record);
                      // setShowModal(true);
                    }}
                  >
                    See More
                  </StyledButton>
                  {/* <a>See More</a> */}
                </Link>
              </Space>
            );
          },
        },
      ],
    },
    {
      title: "Mobile Users",
      data: bodyData,
      totalPages: paginationData?.totalPages,
      paginationData: paginationData,
      filterHandler: checkedRatedUsers ? mobileFilter : onFilter,
      activityFilterHandler: activityFilter,
      dateRange: filterDate,
      searchHandler: onSearch,
      fetch: fetchAll,
      columns: [
        {
          title: "Full Name",
          dataIndex: "fullname",
          key: "fullname",
          render: (text, record) => <Link     
          to={{
            pathname: `/admin/mobile_user_details/${record._id}`,
            state: { renewal: false },
            // id: record.key
          }}
        ><LinkText title={text}/></Link>,
        },
        // {
        //   title: "Email",
        //   dataIndex: "email",
        // },
        {
          title: "Phone",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Gender",
          dataIndex: "gender",
          key: "gender",
        },
        {
          title: "Total Waste Collected",
          dataIndex: "totalWaste",
          key: "totalWaste",
        },
        {
          title: "Total Wallet Balance",
          dataIndex: "previousBalance",
          key: "previousBalance",
        },
        // {
        //   title: "Completed Schedules",
        //   dataIndex: "schedulePoints",
        //   key: "schedulePoints",
        // },
        // {
        //   title: "User Category",
        //   dataIndex: "accountType",
        //   key: "accountType",
        //   render: (_, record) => {
        //     return <Space size="middle">{record?.accountType?.title}</Space>;
        //   },
        // },

        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Link
                  to={{
                    pathname: `/admin/user_transactions/${record._id}`,
                    state: { renewal: false },
                    // id: record.key
                  }}
                >
                  <LinkText title={'View Transaction History'}/>
                  {/* <StyledButton
                    type=""
                    buttonStyle="btn--primary--outline"
                    buttonSize="btn--small"
                    onClick={() => {
                      setRowInfo(record);
                      // setShowModal(true);
                    }}
                  >
                    See More
                  </StyledButton> */}
                  {/* <a>See More</a> */}
                </Link>
              </Space>
            );
          },
        },
      ],
    },
  ];

  return (
    <>
      <InfoModal
        showModal={showModal}
        setShowModal={setShowModal}
        data={rowInfo}
        userData={rowInfo}
      />
      <div className="flex flex-col gap-3">
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container">
          {totalUserCopy?.map((el, i) => {
            return (
              <ContentCard
                ImgUrl={el.icon}
                title={el.title}
                // amount={Result[el.key]}
                amount={el.user}
                style={{ color: colors[i] }}
                link={el.link}
                key={i}
              />
            );
          })}
        </div>
        <Tabcontent
          data={data}
          totalPages={totalPages}
          onRefresh={onRefresh}
          setCurrentPage={setCurrentPage}
          onSwitch={onSwitch}
          ratedUsers={checkedRatedUsers}
          setRatedUsers={setCheckedRatedUsers}
        />
        {/* <DataTable
          data={bodyData || []}
          columns={columns}
          header
          onSearch={onSearch}
          onFilter={onFilter}
          onRefresh={onRefresh}
          setCurrentPage={setCurrentPage}
          totalPages={paginationData?.totalPages}
          paginationData={paginationData}
          onFetch={fetchAll}
        /> */}
      </div>
    </>
  );
};

export default TotalUser;
























// import { Space } from "antd";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import ContentCard from "../../components/UI/ContentCard";
// import InfoModal from "../../components/UI/InfoModal";
// import Tabcontent from "../../components/UI/TabContent";
// import StyledButton from "../../components/UI/btn";
// import {
//   currentMonthUser,
//   filterPointUssdUser,
//   filterUser,
//   filterUssdUser,
//   mobileFilterUser,
//   searchUser,
//   searchUssdUser,
//   totalUser,
//   totalUssdUsers,
// } from "../../store/actions";
// import { TotalCardUser, colors } from "../../utils/data";

// const TotalUser = () => {
//   /****************************
//    *
//    * states and hooks
//    *
//    ****************************/
//   const [showModal, setShowModal] = useState(false);
//   const [rowInfo, setRowInfo] = useState([]);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [bodyData, setBodyData] = useState();
//   const [paginationData, setPaginationData] = useState();
//   const dispatch = useDispatch();
//   const [ussdPaginationData, setUssdPaginationData] = useState([]);
//   const [ussdBodyData, setUssdBodyData] = useState([]);
//   const [selectedKey, setSelectedKey] = useState("0");
//   const [checkedRatedUsers, setCheckedRatedUsers] = useState(false);

//   const d = new Date();

//   d.setDate(d.getDate());
//   const payload = {
//     start: "2020-01-01",
//     end: d,
//   };

//   const onSearch = async (key, page = 1) => {
//     const res = await dispatch(
//       searchUser({
//         key,
//         page,
//       })
//     );
//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setBodyData(users);
//       setPaginationData({ ...paginationData, key });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   const onFilter = async (date, page = 1) => {
//     const res = await dispatch(
//       filterUser({
//         page,
//         currentMonth: date,
//       })
//     );

//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setBodyData(users);
//       setPaginationData({ ...paginationData, date });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   const mobileFilter = async (date, page = 1) => {
//     const res = await dispatch(
//       mobileFilterUser({
//         page,
//         currentMonth: date,
//       })
//     );

//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setBodyData(users);
//       setPaginationData({ ...paginationData, date });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   const thisMonth = useSelector((state) => state?.user);
//   const { currentMonthClient, allUser } = thisMonth;

//   useEffect(() => {
//     if (!allUser) dispatch(totalUser());
//   }, []);

//   const totalUserCopy = TotalCardUser.map((el) => {
//     return { ...el };
//   });

//   totalUserCopy[0].user = allUser?.male;
//   totalUserCopy[1].user = allUser?.female;
//   totalUserCopy[2].user = allUser && allUser?.female + allUser?.male;

//   const fetchAll = async (page = 1) => {
//     const res = await dispatch(
//       currentMonthUser({
//         page,
//         currentMonth: payload,
//       })
//     );
//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setBodyData(users);
//       setPaginationData({ ...paginationData, date: payload });
//     }
//   };

//   const fetchAllUssdUsers = async (page = 1) => {
//     const res = await dispatch(totalUssdUsers({ currentMonth: payload, page }));
//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setUssdBodyData(users);
//       setUssdPaginationData({ ...paginationData, date: payload });
//     }
//   };

//   const searchUssdUsers = async (key, page = 1) => {
//     const res = await dispatch(
//       searchUssdUser({
//         key,
//         page,
//       })
//     );

//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setUssdBodyData(users);
//       setUssdPaginationData({ ...paginationData, key });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   const filterUssdUsers = async (date, page = 1) => {
//     const res = await dispatch(
//       filterUssdUser({
//         page,
//         currentMonth: date,
//       })
//     );

//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setUssdBodyData(users);
//       setUssdPaginationData({ ...paginationData, date });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   const pointFilterUssdUsers = async (date, page = 1) => {
//     const res = await dispatch(
//       filterPointUssdUser({
//         page,
//         currentMonth: date,
//       })
//     );

//     if (!res.error) {
//       const { users, ...paginationData } = res.payload.data;
//       setUssdBodyData(users);
//       setUssdPaginationData({ ...paginationData, date });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   useEffect(() => {
//     fetchAllUssdUsers();
//   }, []);

//   const onRefresh = () => {
//     fetchAll();
//     fetchAllUssdUsers();
//   };

//   const onSwitch = (key) => {
//     setSelectedKey(key);
//   };

//   useEffect(() => {
//     onRefresh();
//   }, []);
//   const data = [
//     {
//       title: "USSD Users",
//       data: ussdBodyData,
//       totalPages: ussdPaginationData?.totalPages,
//       paginationData: ussdPaginationData,
//       filterHandler: checkedRatedUsers ? pointFilterUssdUsers : filterUssdUsers,
//       searchHandler: searchUssdUsers,
//       fetch: fetchAllUssdUsers,

//       columns: [
//         {
//           title: "Full Name",
//           dataIndex: "fullname",
//           key: "fullname",
//           // render: (text) => <a>{text}</a>,
//         },
//         {
//           title: "User Category",
//           dataIndex: "accountType",
//           key: "accountType",
//           render: (_, record) => {
//             return <Space size="middle">{record?.accountType?.title}</Space>;
//           },
//         },
//         {
//           title: "Email",
//           dataIndex: "email",
//           key: "email",
//         },
//         {
//           title: "Completed Schedules",
//           dataIndex: "schedulePoints",
//           key: "schedulePoints",
//         },

//         {
//           title: "Customer Phone",
//           dataIndex: "phone",
//           key: "phone",
//         },

//         {
//           title: "Action",
//           dataIndex: "action",
//           key: "action",
//           render: (text, record) => {
//             return (
//               <Space size="middle">
//                 <Link
//                   to={{
//                     pathname: `/admin/user_details/${record._id}`,
//                     state: { renewal: false },
//                     // id: record.key
//                   }}
//                 >
//                   <StyledButton
//                     type=""
//                     buttonStyle="btn--primary--outline"
//                     buttonSize="btn--small"
//                     onClick={() => {
//                       setRowInfo(record);
//                       // setShowModal(true);
//                     }}
//                   >
//                     See More
//                   </StyledButton>
//                   {/* <a>See More</a> */}
//                 </Link>
//               </Space>
//             );
//           },
//         },
//       ],
//     },
//     {
//       title: "Mobile Users",
//       data: bodyData,
//       totalPages: paginationData?.totalPages,
//       paginationData: paginationData,
//       filterHandler: checkedRatedUsers ? mobileFilter : onFilter,
//       searchHandler: onSearch,
//       fetch: fetchAll,
//       columns: [
//         {
//           title: "Full Name",
//           dataIndex: "fullname",
//           key: "fullname",
//           // render: (text) => <a>{text}</a>,
//         },
//         {
//           title: "Email",
//           dataIndex: "email",
//         },
//         {
//           title: "Customer Phone",
//           dataIndex: "phone",
//           key: "phone",
//         },
//         {
//           title: "Completed Schedules",
//           dataIndex: "schedulePoints",
//           key: "schedulePoints",
//         },
//         {
//           title: "User Category",
//           dataIndex: "accountType",
//           key: "accountType",
//           render: (_, record) => {
//             return <Space size="middle">{record?.accountType?.title}</Space>;
//           },
//         },

//         {
//           title: "Action",
//           dataIndex: "action",
//           key: "action",
//           render: (text, record) => {
//             return (
//               <Space size="middle">
//                 <Link
//                   to={{
//                     pathname: `/admin/user_details/${record._id}`,
//                     state: { renewal: false },
//                     // id: record.key
//                   }}
//                 >
//                   <StyledButton
//                     type=""
//                     buttonStyle="btn--primary--outline"
//                     buttonSize="btn--small"
//                     onClick={() => {
//                       setRowInfo(record);
//                       // setShowModal(true);
//                     }}
//                   >
//                     See More
//                   </StyledButton>
//                   {/* <a>See More</a> */}
//                 </Link>
//               </Space>
//             );
//           },
//         },
//       ],
//     },
//   ];

//   // console.log("check", checkedRatedUsers);
//   return (
//     <>
//       <InfoModal
//         showModal={showModal}
//         setShowModal={setShowModal}
//         data={rowInfo}
//         userData={rowInfo}
//       />
//       <div className="flex flex-col gap-3">
//         <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container">
//           {totalUserCopy?.map((el, i) => {
//             return (
//               <ContentCard
//                 ImgUrl={el.icon}
//                 title={el.title}
//                 // amount={Result[el.key]}
//                 amount={el.user}
//                 style={{ color: colors[i] }}
//                 link={el.link}
//                 key={i}
//               />
//             );
//           })}
//         </div>
//         <Tabcontent
//           data={data}
//           totalPages={totalPages}
//           onRefresh={onRefresh}
//           setCurrentPage={setCurrentPage}
//           onSwitch={onSwitch}
//           ratedUsers={checkedRatedUsers}
//           setRatedUsers={setCheckedRatedUsers}
//         />
//         {/* <DataTable
//           data={bodyData || []}
//           columns={columns}
//           header
//           onSearch={onSearch}
//           onFilter={onFilter}
//           onRefresh={onRefresh}
//           setCurrentPage={setCurrentPage}
//           totalPages={paginationData?.totalPages}
//           paginationData={paginationData}
//           onFetch={fetchAll}
//         /> */}
//       </div>
//     </>
//   );
// };

// export default TotalUser;

